<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import Button from '@cypress-design/vue-button'
import { IconChevronRightSmall } from '@cypress-design/vue-icon'
import Modal from '@cypress-design/vue-modal'
import HubspotForm from '@/components/Vue/HubspotForm.vue'
import type { Props as FormProps } from '@/components/Vue/HubspotForm.vue'
import { hubspotModalDataId, hubspotModalInstanceId } from '@/stores'
import useHubspotForm from '@/utilities/useHubspotForm'
import useMounted from '@/utilities/useMounted'

const $HubspotForm = ref<HTMLFormElement>()
const $form = computed(() => $HubspotForm.value?.$el)

const mounted = useMounted()

const props = defineProps<{
  form: FormProps
  action: string
  instance: string
}>()

const { submit, sending, processingError, success } = useHubspotForm($form, props.action, {
  validate() {
    return $HubspotForm.value?.validate()
  },
  onSubmit(formData) {
    emit('submit', formData)
  },
})

watch(success, (value) => {
  if (value) {
    emit('success')
  }
})

watch(hubspotModalInstanceId, (value) => {
  if (value === props.instance) {
    processingError.value = ''
    sending.value = false
    success.value = false
  }
})

const show = computed({
  get: () => hubspotModalInstanceId.value === props.instance,
  set: (value) => {
    if (!value) {
      hubspotModalInstanceId.value = ''
    }
  },
})

const emit = defineEmits<{
  (event: 'submit', formData: Record<string, any>): void
  (event: 'success'): void
}>()
</script>

<template>
  <Modal v-if="mounted" v-model:show="show" :title="form.name">
    <div v-if="processingError" class="font-secondary text-red-500">
      <p>Sorry, something went wrong</p>
      <details class="text-[12px] leading-[16px]">
        <summary class="cursor-pointer"><IconChevronRightSmall class="mr-[16px] inline" />Error details</summary>
        <pre class="my-[16px]">{{ processingError }}</pre>
      </details>
    </div>
    <p v-else-if="success" class="mx-[24px] my-[32px] text-center font-secondary text-gray-700">
      {{ form.configuration?.postSubmitAction?.value || 'Thank you!' }}
    </p>
    <template v-else>
      <HubspotForm v-bind="form" ref="$HubspotForm" />
      <p class="m-[8px] mb-[80px] text-[12px] text-gray-400">
        By submitting this form you are agreeing to Cypress'
        <a class="text-indigo-300" target="_blank" href="/privacy-policy/">Privacy Policy</a> and
        <a class="text-indigo-300" target="_blank" href="/terms-of-use/">Terms of Use</a>.
      </p>
      <div v-if="sending" data-cy="hubspot-spinner" class="absolute left-0 top-0 z-10 h-full w-full bg-white/70" />
      <div
        class="absolute bottom-0 left-0 right-0 z-50 flex h-[72px] items-center justify-start gap-[16px] rounded-b border-t border-gray-100 bg-gray-50 px-[24px]"
      >
        <Button
          :data-cy="['hubspot-modal-submit', hubspotModalDataId].filter(Boolean).join('-')"
          type="submit"
          @click.prevent="submit"
        >
          Submit
        </Button>
        <Button
          :data-cy="['hubspot-modal-cancel', hubspotModalDataId].filter(Boolean).join('-')"
          variant="white"
          class="!bg-transparent"
          @click="hubspotModalInstanceId = ''"
        >
          Cancel
        </Button>
        <div class="grow text-right text-gray-700">
          <span class="mr-[4px] text-[20px] text-red-400">*</span>required fields
        </div>
      </div>
    </template>
  </Modal>
</template>
