<script lang="ts" setup>
import { computed, onActivated, reactive, ref, watch } from 'vue'

import type { FieldProps as HubSpotFieldDefinition } from './Inputs/GenericInput.vue'
import GenericInput from './Inputs/GenericInput.vue'

export interface Props {
  id: string
  name: string
  configuration?: {
    postSubmitAction?: {
      value: string
    }
  }
  fieldGroups: {
    fields: HubSpotFieldDefinition[]
  }[]
  defaults?: Record<string, string>
}

const props = withDefaults(defineProps<Props>(), { defaults: () => ({}) })

const values = reactive<Record<string, string>>({})

function calculateVisibility(condition: { operator: string; values: string[] }, value: string) {
  switch (condition.operator) {
    case 'set_any':
      return condition.values.includes(value)
    default:
      return false
  }
}

const fieldNames = computed(() => {
  return props.fieldGroups.flatMap((fg) =>
    ([] as string[]).concat(
      fg.fields?.map((f) => f.name) ?? [],
      fg.fields?.flatMap((f) => f.dependentFields?.map((df) => df.dependentField.name) ?? []),
    ),
  )
})

watch(
  () => props.defaults,
  (defaults) => {
    fieldNames.value.forEach((key) => {
      values[key] = defaults[key]
    })
  },
  { immediate: true },
)

const enrichedFieldGroup = computed<
  {
    fields: HubSpotFieldDefinition[]
    hidden?: boolean
  }[]
>(() => {
  return props.fieldGroups.map((fg) => {
    if (fg.fields?.every((f) => f.hidden)) {
      return { hidden: true, ...fg }
    }
    return fg
  })
})

const $fields = ref<InstanceType<typeof GenericInput>[]>([])

function validate() {
  const invalidFields = $fields.value.filter((field) => {
    return !field.validate()
  })
  return !invalidFields.length
}

defineExpose({
  validate,
})

onActivated(() => {
  Object.keys(values).forEach((key) => {
    values[key] = ''
  })
})
</script>

<template>
  <form action="/hubspot" method="post" class="relative flex flex-col gap-[16px] p-[8px]">
    <input type="hidden" name="formId" :value="id" />
    <template v-for="(group, index) in enrichedFieldGroup" :key="index">
      <div
        class="relative flex flex-wrap gap-[16px]"
        :class="{
          hidden: group.hidden,
        }"
      >
        <template v-for="field in group.fields" :key="field.name">
          <template v-if="field.hidden">
            <input type="hidden" :name="field.name" :value="field.defaultValues?.[0] ?? field.defaultValue" />
          </template>
          <template v-else>
            <GenericInput ref="$fields" v-bind="field" v-model="values[field.name]" :form-id="id" />

            <template v-if="field.dependentFields">
              <template v-for="dependentField in field.dependentFields" :key="dependentField.dependentField.name">
                <GenericInput
                  v-if="calculateVisibility(dependentField.dependentCondition, values[field.name])"
                  ref="$fields"
                  v-bind="dependentField.dependentField"
                  v-model="values[dependentField.dependentField.name]"
                  :form-id="id"
                />
              </template>
            </template>
          </template>
        </template>
      </div>
    </template>
  </form>
</template>
